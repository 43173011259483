import { DataLayerEvents } from "./events";

/**
 * @deprecated prefer {@link sendDataLayerEvent}, which includes type signatures for specific events.
 * this should only be used temporarily as we normalize how we send events
 *
 * there are 2 use cases for this function:
 * - tracking for an event which was not ported from UA to GA4.
 *   in this case, we should verify with product if the event needs to be ported, or can be removed safely.
 * - tracking for an event that was ported from UA to GA4, but has missing/extra properties which have not been consolidated.
 *   in this case, we should verify with product which properties are expected so we can update the spec + implementation to match.
 */
export function sendDataLayerEventUnsafe(
  event: string,
  params?: {
    [key: string]: unknown;
  }
) {
  window.dataLayer.push({
    event,
    ...params,
  });
}

type EmptyEvents = {
  [Key in keyof DataLayerEvents]: undefined extends DataLayerEvents[Key]
    ? Key
    : never;
}[keyof DataLayerEvents];
type NonEmptyEvents = Exclude<keyof DataLayerEvents, EmptyEvents>;

function sendDataLayerEventSafe<T extends EmptyEvents>(event: T): void;
function sendDataLayerEventSafe<T extends NonEmptyEvents>(
  event: T,
  params: DataLayerEvents[T]
): void;
function sendDataLayerEventSafe<T extends keyof DataLayerEvents>(
  event: T,
  params?: DataLayerEvents[T]
) {
  sendDataLayerEventUnsafe(event, params);
}

export const sendDataLayerEvent =
  sendDataLayerEventUnsafe as typeof sendDataLayerEventSafe;
