import { XF } from "./XF";
import { sendDataLayerEventUnsafe } from "./dataLayer";

XF.Event.extend("push-cta", {
  init() {
    if (
      XF.config.skipPushNotificationCta ||
      !XF.Push.isSupported() ||
      Notification.permission === "denied" ||
      this.getDismissCookie() ||
      XF.Push.hasUserPreviouslyDenied(XF.config.userId)
    ) {
      this.removeNotification();
      return;
    }

    if (
      Notification.permission === "granted" &&
      XF.Push.hasUserPreviouslySubscribed(XF.config.userId)
    ) {
      this.removeNotification();
      this.registerWorker();
      return;
    }

    this.registerWorker();

    $(".js-enablePushLink").on("click", XF.proxy(this, "linkClick"));
    $("#dismiss-push-notifications-notice").on(
      "click",
      XF.proxy(this, "dismissPerm")
    );
    $(document).on("push:init-subscribed", XF.proxy(this, "hidePushContainer"));
    $("#enable_push_notifications")
      .closest(".notices--floating")
      .on("animationend webkitAnimationEnd mozAnimationEnd", () => {
        pushNotifTracking("Shown", "Prompt shown to user");
      });
  },

  removeNotification() {
    this.hidePushContainer();
    this.setDismissCookie(true);
  },

  hidePushContainer() {
    $("#enable_push_notifications").closest(".notice").remove();
  },
});

function pushNotifTracking(context, action) {
  sendDataLayerEventUnsafe("PushNotification", {
    context,
    action,
  });
}

$(() => {
  if (!XF.config.userId) {
    XF.Cookie.remove("push_notice_dismiss");
  }
});
$(document).on("click", ".js-enablePushLink", () => {
  pushNotifTracking("Dismissal", "Push notifications enabled");
});
$(document).on("click", "#dismiss-push-notifications-notice", () => {
  pushNotifTracking("PermanentDismissal", "Prompt permanently dismissed");
  XF.ajax("post", "/disable-push-subscription");
});

/**
 * Copied from core.js
 * Function called when the user's subscription has been updated
 */
XF.Push.updateUserSubscription = function updateUserSubscription(
  subscription: PushSubscription,
  type: string
) {
  if (type === "update" && XF.Cookie.get("push_subscription_updated")) {
    return;
  }

  const key = subscription.getKey("p256dh");
  const token = subscription.getKey("auth");
  const encoding = (PushManager.supportedContentEncodings || ["aesgcm"])[0];

  $.ajax({
    url: XF.canonicalizeUrl("index.php?misc/update-push-subscription"),
    type: "post",
    data: {
      endpoint: subscription.endpoint,
      key: key
        ? window.btoa(String.fromCharCode.apply(null, new Uint8Array(key)))
        : null,
      token: token
        ? window.btoa(String.fromCharCode.apply(null, new Uint8Array(token)))
        : null,
      encoding,
      unsubscribed: type === "unsubscribe" ? 1 : 0,
      _xfResponseType: "json",
      _xfToken: XF.config.csrf,
    },
    cache: false,
    dataType: "json",
    global: false,
  }).always(() => {
    const expirationTime = subscription.expirationTime
      ? subscription.expirationTime - 86400000
      : 1209600000;

    if (type === "update") {
      XF.Cookie.set(
        "push_subscription_updated",
        "1",
        new Date(Date.now() + expirationTime)
      );
    }
  });
};

/**
 * Checks to see if the user's ID is in local storage, and returns true in the event that a key with their ID has a property of 'false'
 */
XF.Push.hasUserPreviouslyDenied = function hasUserPreviouslyDenied(userId) {
  const userIdHistory = XF.Push.getPushHistoryUserIds();
  const validUserId = userId || XF.config.userId;

  return userIdHistory[validUserId] === false;
};

XF.Push.removeUserFromPushHistory = function removeUserFromPushHistory(userId) {
  const userIdHistory = XF.Push.getPushHistoryUserIds();
  userIdHistory[userId || XF.config.userId] = false;
  XF.Push.setPushHistoryUserIds(userIdHistory);
};
